import { defineComponent } from "vue";

export default defineComponent({
    props: {
        value: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    emits: ['change'],
    setup(p, { emit }) {

        const clickItem = (e: { label: string, value: string }) => {
            emit('change', e.value)
        }
        const handleInput = (e: InputEvent) => {
            emit('change', (e.target as HTMLInputElement).value)
        }
        return {
            clickItem,
            handleInput,
        }
    },
    render() {
        let strstyle = 'border: 1px solid #d9d9d9;background-color: #f5f5f5;'
        return (
            <>
                <a-input
                    size='large'
                    allowClear
                    disabled={this.disabled}
                    value={this.value}
                    onChange={this.handleInput}
                />

                <div class={`${this.disabled && 'cursor-not-allowed'}`} style={`${this.disabled && strstyle}`}>
                    <ul

                        class={`checkbox_button ${this.disabled && 'pointer-events  opacity-0-4-10'}`}
                    >
                        {
                            this.options.map((item: any, index: any) => (
                                <div class={`${item.disabled && 'cursor-not-allowed'}`}>
                                    <li
                                        style={`${item.disabled && strstyle}`}
                                        onClick={() => this.clickItem(item)}
                                        class={{ 'active': item.bOn, 'item point': true, 'pointer-events': item.disabled }}
                                    >
                                        <span class='padding-left-10 padding-right-10'>{item.label}</span>
                                    </li>
                                </div>
                            ))
                        }
                    </ul >
                </div>
            </>

        )
    }

})
