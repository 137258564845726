import { RowNode } from "ag-grid-community";

/**
 * 
 * @param key 
 * @param value 
 * @param nodes 
 * @param row 
 * @returns boolean
 */
export const updatRowForNodes = (key: string, value: any, nodes: RowNode[], row: any): boolean => {
    return nodes.some(x => {
        let isFind = x.data[key] === value;
        isFind && x.updateData(row);
        return isFind;
    });
}
/**
 * 给rowdata带上下拉
 * @param arr 要处理的表格数据rowdata
 * @param selectoption 下拉数据
 * @returns 处理好的数据
 */
export const setrowselect = (arr?: any, selectoption?: any,) => {

    arr.map((x: { [x: string]: any; }) => {
        Object.keys(selectoption).map(k => x[`_${k}`] = selectoption[k])
    })

    return arr
}
/**
 * 给rowdata带上下拉
 * @param arr 
 * @param selectoption 
 * @returns 处理自身 有值额外加上 自身当前值
 */
export const setrowselect_addValue2Options = (arr?: any, selectoption?: any,) => {

    arr.map((x: { [x: string]: any; }) => {
        Object.keys(selectoption).map(k => {
            let arr1 = selectoption[k];
            let arr2 = [...arr1];
            let v = x[k];
            if (!arr1.some((y: { value: any; }) => y.value === v)) {
                v?.trim() && arr2.unshift({ value: v, label: v })
            }
            x[`_${k}`] = arr2;
        })
    })

    return arr
}
