import { CaretRightOutlined, DownloadOutlined, DownOutlined, SearchOutlined } from "@/antd-icon";
import AgGrid from "@/components/ag-table";
import DateRenderer from "@/components/ag-table/date";
import edit_cellRendererComponent from '@/components/ag-table/edit_cell';
import Card from "@/components/form-list-group/components/card";
import QueryModal from '@/components/form-list-group/components/drawer';
import PaginationC from '@/components/pagination/index';
import RouteService from "@/share/router.service";
import useStore from '@/store/metaData';
import useWoStore from "@/store/woManagement";
import { EMPTY_ID } from "@/utile";
import type { FormInstance } from 'ant-design-vue';
import { defineComponent, inject, onUnmounted, ref } from "vue";
import { useRouter } from "vue-router";
import actionCellRenderer from "../ag-table/actionCellRenderer";
import { patchValue } from "../form";
import Collapse from "./components/Collapse";
import _Preview from './components/Preview/index';
import _ProjectPhoto from './components/ProjectPhoto';
import ShowPhoto from "./components/ShowPhoto";
import _Torrent from './components/Torrent/index';
import _Upload from './components/Upload/index';
import Documents from './components/documents';
import getForm from './Form';
import getButton from "./handleButton";
import List from "./List";
import ListModal from "./components/ListModal";
import getPaginationList from './PaginationList';
import PartIILists from "./Part/PartList2";
import PartIIILists from "./Part/PartList3";
import PartListIV from "./Part/partList4";
import { Part1 } from "./Part/usePart";
import { ComponentBase, ComponentConfig, MQ, PartIIList, Preview, ProjectPhoto, QueryDialog, Responsive, RowStructure, SubsectionCard, TabProps, Torrent, Upload } from "./types";
import useEvent, { Form_Event, Form_List_Group_Event } from "./use/useEvent";
import useImportExcel from './use/useImportExcel';
import { handel_api_after } from "./use/use_handle_api_before_after";
import useList from "./useList";
import userForm from './userForm';
import { updatRowForNodes } from "@/utile/table";

export default defineComponent({
    components: {
        PaginationC, QueryModal, Card,
        CaretRightOutlined,
        edit_cellRenderer: edit_cellRendererComponent,
        actionCellRenderer,
        AgGrid, DateRenderer, Documents, List,
        DownOutlined, DownloadOutlined, SearchOutlined,
        PartIIList, PartIIILists, PartListIV, _Preview,
        _ProjectPhoto, _Upload
    },
    props: {
        options: {
            default: () => new TabProps
        },
        gobalSearchShowText: {
            type: Array,
            default: () => []
        },
        componentParent: {
            type: Object,
            default: {}
        }
    },
    setup(prop) {
        const part1 = Part1()

        const primaryKey = prop.options.pageConfig?.formPrimaryKey as string;
        const documentRef = ref();
        const listmodalRef = ref();
        const importRef = ref();
        const formItemsRef = ref();
        const formItemDataRef = ref();
        const ListRef = ref();
        const CustomfromRef = ref();
        const CustomfromRef_s = ref();
        const queryBtnModalRef = ref();
        const modalRef = ref()
        const formRef = ref<FormInstance>();
        const formBtnRef = ref<FormInstance>();
        const tableRef = ref();
        const PartlistRef2 = ref()
        const PartlistRef3 = ref()
        const PartlistRef4 = ref()
        const ShowPhotoref = ref()
        const PreviewRef = ref()
        const ProjectPhotoRef = ref()
        const UploadRef = ref()
        const uploadRef = ref()
        const TorrentRef = ref()
        const MarkAsInactiveCheckboxRef = ref()
        const refs = {
            documentRef, listmodalRef, importRef, formItemsRef, formItemDataRef,
            ListRef, CustomfromRef, CustomfromRef_s, queryBtnModalRef, modalRef,
            formRef, formBtnRef, tableRef, PartlistRef2, PartlistRef3, PartlistRef4, ShowPhotoref, part1,
            PreviewRef, ProjectPhotoRef, UploadRef, uploadRef, TorrentRef, MarkAsInactiveCheckboxRef
        }
        const {
            model,
            canSave, canDel, isAdd, canNew, isSel,

            getRow, toggle, clear,
            cancel, formChange, queryFormSearch

        } = userForm(prop.options, refs);

        const router = useRouter();
        RouteService.$currentRouter = router;


        const woStore = useWoStore();

        const finish = () => {
            if (model[primaryKey] != EMPTY_ID && !prop.options.pageConfig?.isVirtualFormPrimaryKey) {
                edit();
            } else isAdd.value ? add() : edit();
        }

        const {
            pagination, data,
            canExport, canImport, canSaveReportAs,

            save, saveList, getList,
            onSelectionChanged, add,
            edit, del, newRow, getInit,
            HandButtonClick, HandListClick, photo_modal,
            getAccept, i_selectFiles, NewVersion,
            resetTableSort

        } = useList(prop.options, model, refs, canSave, isSel);

        const FormEvent: Form_List_Group_Event = Form_Event;

        const EV = useEvent();
        EV.$getListEv = FormEvent.$getList.subscribe(() => {
            getList()
        });
        // onSortChanged
        const {
            ImportExcel,
            selectFiles,
            excelRef,
        } = useImportExcel(prop.options, data, model);

        onUnmounted(() => {
            EV.$getListEv.unsubscribe();
        })
        let store = useStore();


        // 弹出框
        const listModalSelectId = ref()
        const ListChange = (e: any) => {
            listModalSelectId.value = e
        }

        // 关了modal后再调一次选中接口用来更新表格
        const refreshPhoto = (id: any) => {
            const { ID } = id
            let obj = { ID, LoginName: store.userName }
            if (prop.options?.pageConfig?.refreshmodel_getrow) {
                prop.options.http?.getRow && prop.options.http.getRow(obj).then(x => {
                    let Entity = x.Entity
                    ListRef.value?.setRowData(Entity.ItemList || [])
                    prop.options.pageConfig?.api_after?.refreshPhoto && handel_api_after(x, prop.options.pageConfig?.api_after?.refreshPhoto, refs);

                    patchValue(model, { ItemList: Entity.ItemList || [] })

                    try {
                        let nodes = tableRef.value.getAllNodes();
                        updatRowForNodes(primaryKey, Entity[primaryKey], nodes, {
                            ...Entity,
                            Photo: Entity?.EngineeringPhotoList?.length ?? 0,
                            _StatusCount_: Math.random(),
                            StatusCount: Entity?.ItemList?.filter((y: { Status: string; }) => y?.Status === "1")?.reduce((a: number, b: { ItemCount: number; }) => a + b.ItemCount, 0) || 0,
                            ComplianceCount: Entity?.ItemList?.filter((y: { Status: string; }) => y?.Status === "2")?.length || 0,
                            NotUTCount: Entity?.ItemList?.filter((y: { Status: string; }) => y?.Status === "0")?.length || 0,
                            Subcontractor: Entity.Subcontractor === 'New SC' ? Entity.SubcontractorValue : Entity.Subcontractor
                        })
                    } catch (error) {

                    }
                })
            }
        }
        /**part2新增和删除返回part3数据，直接调用part3获取数据方式 */
        const GetPart3data = (a: any) => {
            PartlistRef3.value.setRowData(a)
        }
        return {
            // 
            ...refs,
            woStore, excelRef, listModalSelectId,
            ImportExcel, selectFiles, refreshPhoto,
            GetPart3data, ListChange, finish,
            // Form
            model,
            canSave, canDel, isAdd, canNew, isSel,

            getRow, toggle, clear,
            cancel, formChange, queryFormSearch,

            // List
            pagination, data,
            canExport, canImport, canSaveReportAs,

            save, saveList, getList,
            onSelectionChanged, add,
            edit, del, newRow, getInit,
            HandButtonClick, HandListClick, part1, photo_modal,
            getAccept, i_selectFiles, NewVersion,
            resetTableSort

        }
    },
    render() {
        const getComponent = (c: ComponentBase): any => {
            let cp: any = <></>
            let showCardWrap: any, showCardTitle;
            let titleSlot: any = {};
            switch (c.type) {
                case 'BtnsGroup':
                    return getButton.apply(this, [c, this.options, this.model])
                case 'Form':
                    return getForm.apply(this, [c, this.options, this.model])
                case 'PaginationList':
                    return getPaginationList.apply(this, [c, this.options, this.model])
                case 'List':
                    cp = <List ref='ListRef' id={this.model.ID} options={c} />;
                    break;
                case 'Preview':
                    let preview: Preview = c as Preview;
                    cp = <_Preview ref='PreviewRef' option={preview} _this={this}></_Preview>
                    break;
                case 'Torrent':
                    let torrent: Torrent = c as Torrent;
                    cp = <_Torrent ref='TorrentRef' option={torrent} _this={this}></_Torrent>
                    break;
                case 'ProjectPhoto':
                    let projectPhoto: ProjectPhoto = c as ProjectPhoto;
                    cp = <_ProjectPhoto ref='ProjectPhotoRef' option={projectPhoto} _this={this}></_ProjectPhoto>
                    break;
                case 'Upload':
                    let upload: Upload = c as Upload;
                    cp = <_Upload ref='UploadRef' option={upload} _this={this}></_Upload>
                    break;
                case 'PartIIList':
                    cp = <PartIILists ref="PartlistRef2" options={c} id={this.model.ID}
                        onGetPart3data={this.GetPart3data}
                        onOpenPart3={() => {
                            this.PartlistRef3.part3.showDrawer()
                        }}></PartIILists>
                    break;
                case 'PartIIIList':
                    cp = <PartIIILists ref="PartlistRef3" options={c} id={this.model.ID}
                        onOpenPart4={() => {
                            this.PartlistRef4.part4.showDrawer()
                        }} ></PartIIILists>
                    break;
                case 'PartIIIIList':
                    cp = <PartListIV ref="PartlistRef4" options={c} id={this.model.ID} _this={this}></PartListIV>
                    break;
                case 'QueryDialog':
                    let QModal = c as QueryDialog;
                    showCardWrap = QModal.isCardWrap;
                    cp = <QueryModal fields={this.options.queryModalFields} btnText={QModal.btnText} labelWidth={this.options.pageConfig?.queryModallabelWidth} title={QModal.title} onOk={this.getList} okBtnText={QModal.okBtnText}></QueryModal>

                    let QModalSlot = {
                        header: () => {
                            if (QModal.align) {
                                if (showCardWrap) return <div style={{ textAlign: QModal.align, flexGrow: 1 }}>{cp}</div>
                                return <div style={{ textAlign: QModal.align }}>{cp}</div>
                            }
                            return cp;
                        }
                    }
                    if (showCardWrap) return <Card v-slots={QModalSlot} ></Card >
                    return cp;
                case 'Card':
                    let _Card = c as SubsectionCard;
                    let CardSlot = {
                        header: () => {
                            return <b style={{ 'color': '#c60' }}>{_Card.text}</b>
                        }
                    }
                    return _Card.cname == 'showtext' ? <div>{_Card.text}</div> : <Card isFold fold disabled v-slots={CardSlot}></Card>;
                case 'DocumentsList':
                    return <Documents ref='documentRef' id={this.listModalSelectId} options={c} onClosemodal={() => {
                        this.componentParent.modalRef?.close();
                    }} />;
                case 'ListModal':
                    return <ListModal ref='listmodalRef' options={c} id={this.model.ID} onChange={this.ListChange} />;
                case 'FormListGroup':
                    return <form-list-group options={(c as any).options}></form-list-group>;
                case 'ShowPhoto':
                    return <ShowPhoto ref='ShowPhotoref' options={c}></ShowPhoto>;
            }

            if (showCardWrap) {
                if (showCardTitle) {
                    return <Card v-slots={titleSlot}>{cp}</Card>
                }
                return <Card >{cp}</Card>
            }
            return cp;
        }
        // @ts-ignore
        let mq: MQ = inject("mq");
        const getTemp = (arr: RowStructure[]) => {
            return arr.map((row: RowStructure) => getRow(row));
        }
        const getRow = (row: RowStructure) => {
            let el = <a-row class={row.class} gutter={row.gutter}>
                {
                    row.flex1 && getCol(row, row.flex1)
                }
                {
                    row.flex2 && getCol(row, row.flex2)
                }
                {
                    row.flex3 && getCol(row, row.flex3)
                }
            </a-row>;

            if (row.need_collapse) {
                let TextSlot = {
                    header: () => {
                        return <b style={{ 'color': '#000', 'user-select': 'none' }}>{row.show}</b>
                    },
                    open_header: () => {
                        return <b style={{ 'color': '#000', 'user-select': 'none' }}>{row.show}</b>
                    },
                    close_header: () => {
                        return <b style={{ 'color': '#000', 'user-select': 'none' }}>{row.openshow}</b>
                    },
                }
                return <Collapse v-slots={TextSlot}>{el}</Collapse>
            }
            return el
        }
        const getCol = (row: RowStructure, flex: ComponentConfig) => {
            if (flex.component instanceof RowStructure) {
                return <a-col class={flex.class} {...getResponsive(mq, row, flex?.flexProportionNum || 1, flex.responsive)}>
                    {getRow(flex.component)}
                </a-col>
            }
            return <a-col class={flex.class} {...getResponsive(mq, row, flex?.flexProportionNum || 1, flex.responsive)}>
                {getComponent(flex.component)}
            </a-col>
        }
        return (
            <>
                {this.options?.body && getTemp(this.options.body)}
                {
                    // this.data.showUpload ?
                    <input type="file" hidden style="display: none;" value={this.data.files} ref={'uploadRef'} onChange={(e: any) => {
                        this.i_selectFiles(e, this.data?.currentBtn);
                        setTimeout(() => {
                            this.data.files = null;
                        }, 100);
                    }}
                        accept={this.getAccept(this.data?.currentBtn)}
                    />
                    // : ''
                }
            </>
        );
    },
})

const getResponsive = (mq: MQ, row: RowStructure, n: number, responsive?: Responsive | number) => {
    let obj: any = {}
    let span: number = 24;
    // :xs="2" :sm="4" :md="6" :lg="8" :xl="10"
    if (!responsive) responsive = span;
    // 如果是数字，直接把数字值 赋值给 span。 不做详细的响应式
    if ("number" == typeof responsive) {
        obj = { span: responsive }
        if (row.flex3) {

        } else if (row.flex2) {

        }
    } else { // 处理 详细 的 响应式
        if (responsive?.minWidth && responsive.maxWidth) { // 有最小宽度 和 最大宽度
            obj = { style: { minWidth: responsive.minWidth, maxWidth: responsive.maxWidth } }
        } else { // 直接把 :xs="2" :sm="4" :md="6" :lg="8" :xl="10" 给 obj
            obj = { ...responsive };
        }
    }
    return obj
}
