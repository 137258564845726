import { EnterOutlined, SaveOutlined } from "@/antd-icon"
import { setrowselect, setrowselect_addValue2Options, updatRowForNodes } from "@/utile/table"
import DownloadService from '@/http/download';
import PhotoEditer from '../../photo-editer'

import useStore from '@/store/metaData'
import { AgGridRowEvent_e } from "../components/documents";
import { getObjectByMap, handleArrayNull, handleDate } from "@/utile";
import { patchValue, patchValue_null } from "../../form";
import { SetDisabled } from "@/utile/form";
import { ListResult } from "@/components/form-list-group/types/types"
import { inject } from "vue";
import { Part2C, Part2D } from "./usePart2";
import { Accept } from "@/types/file";


export function getTemp1(this: any) {
    // 把操作图标禁用了
    const getcolC = this.options.columnDefsC.find((e: any) => e.headerName == "Action")
    const isMobile: any = inject('isMobile')

    if (getcolC) {
        if (this.data.Pstatus) {
            getcolC.cellRendererParams.disabled = true
        } else {
            getcolC.cellRendererParams.disabled = false
        }
    }
    return <div style={'margin :10px 0'}>
        <div style={'font-weight: 700;font-size: 20px;margin:5px 0'}><b>{this.options.titleC}</b></div>
        <div class='flex title' style={'justify-content: space-between'}>
            <div>{this.options.GridTitleC}</div>
            <a-button
                v-BtnPermission={{ type: 'Update' }}
                style={this.options.btn?.style}
                class={this.options.btn?.class}
                type={this.options.btn?.type}
                icon={<i class={`iconfont ${this.options.btn?.icon}`} style={this.options.btn?.iconstyle}></i>}
                onClick={this.openC}
                disabled={this.data.cannew || this.data.Pstatus}
            >{this.options.btn?.btnText}
            </a-button>
            <a-modal
                visible={this.modelV.visibleC}
                width="31%"
                onOk={this.handleOkC}
                onChange={this.closeC}
                closable={false}
                v-slots={{
                    footer: () => {
                        return isMobile.value ? <div>
                            <div style={{ padding: "7px 0" }}>
                                <a-button onClick={this.handleOkC} type="primary" class='ant-btn-yellow'><SaveOutlined style={'padding: 5px;transform: rotateY(180deg);'} />储存</a-button>
                            </div>
                            <div class='previous'>
                                <a-button onClick={this.closeC} type="primary">取消</a-button>
                            </div>
                        </div> : <div style='margin-right:8px'>
                            <a-button onClick={this.closeC} style='color:#1b7aff;border-color: #fff;'>Cancel</a-button>
                            <a-button onClick={this.handleOkC} class='ant-btn-yellow'><EnterOutlined style={'padding: 5px;transform: rotateY(180deg);'} />Confirm</a-button>
                        </div>
                    }
                }}
                wrapClassName={`partmodal ${isMobile.value ? 'partmodal2' : ''}`}
            >
                <b-form
                    fields={this.options.modal1?.fields}
                    model={this.modelC}
                    ref='formrefC'
                    layout="vertical"
                    // labelCol={{ style: { width: "100px" } }}
                    // labelWidth="'100px'"
                    size="large"
                    class="block partmodal"
                    style="flex-grow: 1"
                    onFinish={this.FinishC}
                >
                </b-form>
            </a-modal>
        </div>
        {
            isMobile.value ? <Part2C
                ref="tableRef1"
                rowData={this.data.rowData1}
                _this={this}
            /> :
                <ag-grid
                    ref="tableRef1"
                    style={{ height: this.data.height1 || "200px" }}
                    class="ag-theme-alpine"
                    columnDefs={this.options.columnDefsC}
                    rowData={this.data.rowData1}
                    on_cellMethod={this.cellAction}
                    suppressRowClickSelection={true}
                    on_gridReady={this.onGridReady1}
                // rowHeight={90}
                >
                </ag-grid>
        }
    </div>
}

export function getTemp2(this: any) {
    // 把操作图标禁用了
    const getcolD: any = this.partdD.find((e: any) => e.headerName == "Action")
    const isMobile: any = inject('isMobile')

    if (getcolD) {
        if (this.data.Pstatus) {
            getcolD.cellRendererParams.disabled = true
        } else {
            getcolD.cellRendererParams.disabled = false
        }
    }
    return <div style={'margin :10px 0'}>
        <div style={'font-weight: 700;font-size: 20px;margin:5px 0'}>{this.options.titleD}</div>
        <div class='flex title' style={'justify-content: space-between'}>
            <div>{this.options.GridTitleD}</div>
            <a-button
                v-BtnPermission={{ type: 'Update' }}
                style={this.options.btn?.style}
                class={this.options.btn?.class}
                type={this.options.btn?.type}
                icon={<i class={`iconfont ${this.options.btn?.icon}`} style={this.options.btn?.iconstyle}></i>}
                disabled={this.data.cannew || this.data.Pstatus}
                onClick={this.openD}
            >{this.options.btn?.btnText}
            </a-button>
            <a-modal
                visible={this.modelV.visibleD}
                width="70%"
                onOk={this.handleOkD}
                onChange={this.closeD}
                closable={false}
                v-slots={{
                    footer: () => {
                        return isMobile.value ? <div>
                            <div style={{ padding: "7px 0" }}>
                                <a-button onClick={this.handleOkD} type="primary" class='ant-btn-yellow'><SaveOutlined style={'padding: 5px;transform: rotateY(180deg);'} />储存</a-button>
                            </div>
                            <div class='previous'>
                                <a-button onClick={this.closeD} type="primary">取消</a-button>
                            </div>
                        </div> : <div style='margin-right:8px'>
                            <a-button onClick={this.closeD} style='color:#1b7aff;border-color: #fff;'>Cancel</a-button>
                            <a-button onClick={this.handleOkD} class='ant-btn-yellow'><EnterOutlined style={'padding: 5px;transform: rotateY(180deg);'} />Confirm</a-button>
                        </div>
                    }
                }}
                wrapClassName={`partmodal ${isMobile.value ? 'partmodal2d' : ''}`}
                style='margin-top:-7%'
            >
                {/* <div class='flex'>
                    <div style={'width:50%'} >
                        <b-form
                            fields={this.options.modal2?.fields}
                            model={this.modelD}
                            ref="formrefD"
                            layout="vertical"
                            // labelCol={{ style: { width: "100px" } }}
                            // labelWidth="'100px'"
                            size="large"
                            class="block"
                            style="flex-grow: 0"
                            onFinish={this.FinishD}
                        >
                        </b-form>
                    </div>
                    <div style={'width:50%'} >
                        <a-upload-dragger
                            file-list={this.bb.fileList}
                            name="file"
                            multiple={false}
                            onChange={this.handleChange}
                            onDrop={this.handleDrop}
                            beforeUpload={this.beforeUpload}
                            disabled={this.photo.isDisable}
                        >
                            <div class='upload'>
                                <div>
                                    <img src="/svg/Group.svg" alt="" style=' margin-top:20px' />
                                    <p><b class="ant-upload-text">Drop files to upload</b></p>
                                    <p class="ant-upload-hint">  or click to pick manually </p>
                                </div>
                            </div>
                        </a-upload-dragger>

                    </div>
                </div> */}
                <a-row gutter={20}>
                    <a-col xs={24} xl={24} xxl={12}>
                        <b-form
                            fields={this.options.modal2?.fields}
                            model={this.modelD}
                            ref="formrefD"
                            layout="vertical"
                            // labelCol={{ style: { width: "100px" } }}
                            // labelWidth="'100px'"
                            size="large"
                            class="block"
                            style="flex-grow: 0"
                            onFinish={this.FinishD}
                        >
                        </b-form>
                    </a-col>
                    <a-col xs={24} xl={24} xxl={12}>
                        <a-upload-dragger
                            file-list={this.bb.fileList}
                            name="file"
                            multiple={false}
                            onChange={this.handleChange}
                            onDrop={this.handleDrop}
                            beforeUpload={this.beforeUpload}
                            disabled={this.photo.isDisable}
                            accept={Accept.image}
                        >
                            <div class='upload'>
                                <div>
                                    <img src="/svg/Group.svg" alt="" style=' margin-top:20px' />
                                    <p><b class="ant-upload-text">Drop files to upload</b></p>
                                    <p class="ant-upload-hint">  or click to pick manually </p>
                                </div>
                            </div>
                        </a-upload-dragger>
                    </a-col>
                </a-row>
            </a-modal>
        </div>
        {
            isMobile.value ? <Part2D
                ref="tableRef2"
                rowData={this.data.rowData2}
                _this={this}
                selectOptions={this.data.selectOptions}
            /> :
                <ag-grid
                    ref="tableRef2"
                    style={{ height: this.data.height2 || '200px', '--ag-background-color': '#efefef' }}
                    class="ag-theme-alpine "
                    columnDefs={this.partdD}
                    rowData={setrowselect_addValue2Options(this.data.rowData2, this.data.selectOptions)}
                    rowHeight={90}
                    on_cellMethod={this.cellAction}
                    suppressRowClickSelection={true}
                    suppressRowTransform={true}
                    on_gridReady={this.onGridReady2}
                >
                </ag-grid>
        }
        <PhotoEditer ref={`photoRef`} onSave={this.savePhoto}></PhotoEditer>
    </div>
}

export const crud = (p: { options: any, id: string }, data: any, tableRef1: any, tableRef2: any, emit: any, modalParam: any) => {
    // let api: GridApi = tableRef.value.grid.api
    let PrimaryKey = p.options.pageConfig?.formPrimaryKey;
    const store = useStore();

    let { PartGroupC, PartGroupD } = p.options

    const cellAction = (e: AgGridRowEvent_e) => {

        switch (e.currentAction.toLowerCase()) {
            case 'Save1'.toLowerCase(): SaveC(e); break;
            case 'Delete1'.toLowerCase(): DelC(e); break;
            case 'Save'.toLowerCase(): SaveD(e); break;
            case 'Delete'.toLowerCase(): DelD(e); break;
            case 'Upload'.toLowerCase(): UploD(e); break;
            case 'Add'.toLowerCase(): openD(e); break;
            case 'Rotate'.toLowerCase(): RotateD(e); break;
        }

    }
    // Part2  C
    const SaveC = (e: AgGridRowEvent_e,) => {
        let obj: any = { FID: p.id, LoginName: store.userName, ...e?.data, PartGroup: PartGroupC }
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.edit || [], e?.data) }
        obj = handleDate(obj);

        p.options.http?.editPart2 && p.options.http?.editPart2(obj).then((x: ListResult) => {
            data.rowData1 = x.Data;
            // setTimeout(() => {
            //     tableRef1.value?.grid?.api?.sizeColumnsToFit();
            // }, 0);
            p.options.http?.notify('The Edit was successful.');
        });
    }
    const DelC = (e: AgGridRowEvent_e) => {
        p.options.http?.confirm({
            title: 'Information',
            content: 'Do you want to delete it permanently',
            onOk: async () => {
                let obj: any = { ID: e.data.ID, LoginName: store.userName, PartGroup: PartGroupC }
                if (PrimaryKey) {
                    obj[PrimaryKey] = e?.data[PrimaryKey]
                }
                p.options.http?.delPart2 && await p.options.http?.delPart2(obj).then((x: ListResult) => {
                    data.rowData1 = x.Part2CList.Data;
                    emit('GetPart3data', x)
                    p.options.http?.notify('The deletion was successful.');
                });
                // tableRef1.value?.grid?.api?.sizeColumnsToFit()
            }
        })
    }
    const AddC = async (obj1: any) => {
        let obj: any = {
            PID: p.id, ...obj1, LoginName: store.userName,
            UpdateBy: store.userName, UpdateOn: new Date(),
            CreateBy: store.userName, CreateOn: new Date(),
            PartGroup: PartGroupC
        };
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.add || []), };
        obj = handleArrayNull(obj);
        obj = handleDate(obj);
        p.options.http?.addPart2 && await p.options.http?.addPart2(obj).then((x: ListResult) => {
            data.rowData1 = x.Part2CList.Data;
            emit('GetPart3data', x)
            p.options.http?.notify('The add was successful.');
        });
        // tableRef1.value?.grid?.api?.sizeColumnsToFit()
    }
    // Part2  D
    const SaveD = (e: AgGridRowEvent_e) => {
        let obj: any = { FID: p.id, LoginName: store.userName, ...e?.data, PartGroup: PartGroupD }
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.edit || [], e?.data) }
        obj = handleDate(obj);

        p.options.http?.editPart2 && p.options.http?.editPart2(obj).then((x: ListResult) => {
            data.rowData2 = x.Data;
            p.options.http?.notify('The Edit was successful.');
        });
    }
    const SaveD_P = (e: AgGridRowEvent_e,) => {
        let obj: any = {
            ...e?.data,
            FID: e.data.ID,
            ID: e.data.PhotoID,
            LoginName: store.userName,
            PartGroup: PartGroupD
        }
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.edit || [], e?.data) }
        obj = handleDate(obj);

        p.options.http?.editPart4 && p.options.http?.editPart4(obj).then((x: ListResult) => {
            e.node.setDataValue('FilePath', x.Data[0].FilePath);
            p.options.http?.notify('The Upload was successful.')
        });
    }
    const DelD = (e: AgGridRowEvent_e) => {
        p.options.http?.confirm({
            title: 'Information',
            content: 'Do you want to delete it permanently',
            onOk: async () => {
                let obj: any = { ID: e.data.ID, LoginName: store.userName, PartGroup: PartGroupD }
                if (PrimaryKey) {
                    obj[PrimaryKey] = e?.data[PrimaryKey]
                }
                p.options.http?.delPart2 && await p.options.http?.delPart2(obj).then((x: ListResult) => {
                    data.rowData2 = x.Part2CList.TableData || x.Part2CList.Data;
                    p.options.http?.notify('The deletion was successful.');
                });
                // tableRef2.value?.grid?.api?.sizeColumnsToFit()
            }
        })
    }
    const AddD = (obj1: any) => {
        let obj: any = {
            PID: p.id, ...obj1, LoginName: store.userName,
            UpdateBy: store.userName, UpdateOn: new Date(),
            CreateBy: store.userName, CreateOn: new Date(),
            PartGroup: PartGroupD
        };
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.add || []), };
        obj = handleArrayNull(obj);
        obj = handleDate(obj);
        p.options.http?.addPart2 && p.options.http?.addPart2(obj).then((x: ListResult) => {
            data.rowData2 = x.Part2CList.Data;
            setTimeout(() => {
                // tableRef2.value?.grid?.api?.sizeColumnsToFit()
            }, 300);
            p.options.http?.notify('The add was successful.');
        });
    }
    const UploD = (e: AgGridRowEvent_e) => {
        // @ts-ignore
        DownloadService.upload(e.file.fileList).then((x: { FileList: { MD5: string, Path: string }[]; TempPath: string; }) => {
            if (x.FileList.length) {
                e.node.setDataValue('UploadFilePath', x.FileList[0].Path);
                SaveD_P(e)
            }
        })
    }
    const RotateD = (e: AgGridRowEvent_e,) => {
        // let obj: any = { ...e.node.data, ID: e.data?.PhotoID, LoginName: store.userName, }
        // obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.photoRotate || []) };
        // p.options.http?.photoRotate && p.options.http?.photoRotate(obj).then((x: { FilePath: string }) => {
        //     e.node.data.FilePath = x.FilePath;
        //     e.node.setData(e.node.data);
        // });
        showPhotoEditer(e)
    }
    const { modelV, photo, modelD, prop, modelC, formrefC, formrefD, photoRef } = modalParam
    const openD = (e: AgGridRowEvent_e) => {
        modelV.visibleD = true;
        modelV.path = ''
        photo.isDisable = false
        patchValue_null(modelD)
        // 存在表明是从row点击的，设置disable
        if (e.data) {
            SetDisabled(prop.options.modal2.fields || [], 'LocatioInspection', true);
            // 打开后设置disable的
            // let ar1 = data.rowData2.filter((a: any) => a.ItemNo == e.data.ItemNo)
            // let ar2 = ar1.map((c: any) => c.DeficienciesIdentified)
            // const op = prop.options.modal2.fields[3].fieldGroup[0].templateOptions.options

            // ar2.forEach((w: any) => {
            //     let aa = op.filter((c: any) => c.value == w)[0]
            //     aa && (aa.disabled = true)
            // })

            patchValue(modelD, { 'LocatioInspection': e.data.LocatioInspection })
        } else {
            const op = prop.options.modal2.fields[3].fieldGroup[0].templateOptions.options
            op.map((z: any) => z.disabled = false)

            patchValue(modelD, { 'LocatioInspection': data.initLocation })
            SetDisabled(prop.options.modal2.fields || [], 'LocatioInspection', false);
        }
    };
    const openC = () => {
        modelV.visibleC = true
        patchValue(modelC, { 'LocatioInspection': data.initLocation })
    }
    const handleOkC = (e: any) => {
        formrefC.value.submit()
    };
    // 表单验证一下
    const FinishC = (values: any) => {
        modelV.visibleC = false;
        AddC(values)
        formrefC.value.reset()
    }
    const closeC = () => {
        modelV.visibleC = false;
        formrefC.value.reset()
    };
    const handleOkD = (e: MouseEvent) => {
        formrefD.value.submit()
    };
    // 表单验证一下
    const FinishD = (values: any) => {
        if (modelD.UploadFilePath) {
            modelV.visibleD = false;
            AddD(modelD)
            formrefD.value.reset()
        } else {
            prop.options.http?.error('Please Select One Photo')
        }
    }
    const closeD = () => {
        modelV.visibleD = false;
        formrefD.value.reset()

        const op = prop.options.modal2.fields[3].fieldGroup[0].templateOptions.options
        op.map((e: { disabled: boolean; }) => e.disabled = false)
    };
    const modalFns = {
        FinishC, handleOkC, closeC, openC,
        FinishD, handleOkD, closeD, openD,
    }

    const SaveC_m = (e: any,) => {
        let obj: any = { FID: p.id, LoginName: store.userName, ...e, PartGroup: PartGroupC }
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.edit || [], e) }
        obj = handleDate(obj);

        p.options.http?.editPart2 && p.options.http?.editPart2(obj).then((x: ListResult) => {
            data.rowData1 = x.Data;
            p.options.http?.notify('The Edit was successful.');
        });
    }
    const DelC_m = (e: any) => {
        p.options.http?.confirm({
            title: 'Information',
            content: 'Do you want to delete it permanently',
            onOk: async () => {
                let obj: any = { ID: e.ID, LoginName: store.userName, PartGroup: PartGroupC }
                if (PrimaryKey) {
                    obj[PrimaryKey] = e?.[PrimaryKey]
                }
                p.options.http?.delPart2 && await p.options.http?.delPart2(obj).then((x: ListResult) => {
                    data.rowData1 = x.Part2CList.Data;
                    emit('GetPart3data', x)
                    p.options.http?.notify('The deletion was successful.');
                });
                // tableRef1.value?.grid?.api?.sizeColumnsToFit()
            }
        })
    }
    const SaveD_m = (e: any) => {
        let obj: any = { FID: p.id, LoginName: store.userName, ...e, PartGroup: PartGroupD }
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.edit || [], e) }
        obj = handleDate(obj);

        p.options.http?.editPart2 && p.options.http?.editPart2(obj).then((x: ListResult) => {
            data.rowData2 = x.Data;
            p.options.http?.notify('The Edit was successful.');
        });
    }
    const DelD_m = (e: any) => {
        p.options.http?.confirm({
            title: 'Information',
            content: 'Do you want to delete it permanently',
            onOk: async () => {
                let obj: any = { ID: e.ID, LoginName: store.userName, PartGroup: PartGroupD }
                if (PrimaryKey) {
                    obj[PrimaryKey] = e?.[PrimaryKey]
                }
                p.options.http?.delPart2 && await p.options.http?.delPart2(obj).then((x: ListResult) => {
                    data.rowData2 = x.Part2CList.TableData || x.Part2CList.Data;
                    p.options.http?.notify('The deletion was successful.');
                });
                // tableRef2.value?.grid?.api?.sizeColumnsToFit()
            }
        })
    }
    const openD_m = (e: any) => {
        modelV.visibleD = true;
        modelV.path = ''
        photo.isDisable = false
        patchValue_null(modelD)
        // 存在表明是从row点击的，设置disable
        SetDisabled(prop.options.modal2.fields || [], 'LocatioInspection', true);
        // 打开后设置disable的
        // let ar1 = data.rowData2.filter((a: any) => a.ItemNo == e.data.ItemNo)
        // let ar2 = ar1.map((c: any) => c.DeficienciesIdentified)
        // const op = prop.options.modal2.fields[3].fieldGroup[0].templateOptions.options

        // ar2.forEach((w: any) => {
        //     let aa = op.filter((c: any) => c.value == w)[0]
        //     aa && (aa.disabled = true)
        // })

        patchValue(modelD, { 'LocatioInspection': e.LocatioInspection })

    };
    const UploD_m = (e: any) => {
        let obj: any = {
            ...e,
            FID: e.ID,
            ID: e.PhotoID,
            LoginName: store.userName,
            PartGroup: PartGroupD
        }
        obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.edit || [], e) }
        obj = handleDate(obj);

        p.options.http?.editPart4 && p.options.http?.editPart4(obj).then((x: ListResult) => {
            // e.node.setDataValue('FilePath', x.Data[0].FilePath);
            Object.assign(e, { FilePath: x.Data[0].FilePath })
            p.options.http?.notify('The Upload was successful.')
        });
    }
    const RotateD_m = (e: any,) => {
        // let obj: any = { ...e, ID: e?.PhotoID, LoginName: store.userName, }
        // obj = { ...obj, ...getObjectByMap(p.options.httpParamsMap?.photoRotate || []) };
        // p.options.http?.photoRotate && p.options.http?.photoRotate(obj).then((x: { FilePath: string }) => {
        //     e.FilePath = x.FilePath;
        // });
        showPhotoEditer(e, true)
    }
    const savePhoto = (photo: { ID: string, file: string }) => {
        let obj = {
            Base64String: photo.file, ID: photo.ID,
            LoginName: store.userName, UpdateBy: store.userName
        }
        p.options.http?.photoEdit && p.options.http?.photoEdit(obj).then((x: { FilePath: string, ID: string }) => {
            p.options.http?.notify('Saved successfully..');
            data.rowData2 = data.rowData2.map((y: any) => {
                if (y.PhotoID == x.ID) {
                    y.FilePath = x.FilePath + '?' + Math.random()
                }
                return y
            })
        });

    }
    const showPhotoEditer = (e: any, isMobile: boolean = false) => {
        if (isMobile) {
            photoRef.value.Open({ ID: e.PhotoID, path: e.FilePath });
        } else {
            photoRef.value.Open({ ID: e.data.PhotoID, path: e.data.FilePath });
        }
    }
    return {
        cellAction,
        AddC,
        AddD,
        ...modalFns,
        partc_m: {
            SaveC_m,
            DelC_m
        },
        partd_m: {
            SaveD_m,
            RotateD_m,
            UploD_m,
            openD_m,
            DelD_m,
        },
        savePhoto
    }
}


export const dragger = (pobj: any) => {
    const { photo, prop, DownloadService, modelD } = pobj
    const handleDrop = (e: DragEvent) => {
        console.log('拖拽' + e);
    }
    const beforeUpload = (file: File): boolean => {
        // bb.fileList = bb.fileList.concat(file);
        /**true和false
         * true：立刻上传服务器
         * false：不上传
         */
        return false;
    };
    const delphoto = () => {
        photo.isshow = false
    }
    const handleChange = (info: any) => {
        //判断文件是否小于20M
        let isLt20M: any = info.file.size < 1024 * 1024 * 10;
        if (!isLt20M) {
            prop.options.http.error('The selected file exceeds 20M, and it has been automatically filtered. Please check ~');
            return
        }
        DownloadService.upload([info.file]).then((x: any) => {
            if (x.FileList.length) {
                prop.options.http?.notify('The photo add was successful.');
                patchValue(modelD, {
                    UploadFilePath: x.FileList[0].Path
                })
                // console.log(x);
                photo.isDisable = true
                // photo.isshow = true
                photo.path = x.FileList[0].Path
            }
        })
    }
    return {
        handleChange,
        delphoto,
        beforeUpload,
        handleDrop
    }
}